import React from "react";
import { Container, Col, Row, Card, Image } from "react-bootstrap";
import AutobetInvite from "../../static/Images/autobet-invite.svg";
import "./normal.css";

const FundRaise = () => {
  return (
    <div className="fundRaise">
      <Container className="mt-5 pt-3 mb-4">
        <Row className="align-items-center">
          <Col lg={6}>
            <h1 className="title">
              <span className="red-text-color">AutoBET</span> invites
              crypto-lottery creators that wish to fundraise for
            </h1>
            <p className="fundDesc">
              It is fully automated on smart-contracts that run on the Polygon
              Network. Currently, only POL is accepted as payment and plans are
              in place to integrate its native BET token as well as other major
              digital currencies.
            </p>
          </Col>
          <Col lg={6}>
            <img src={AutobetInvite} className="autobetInvite-img" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default FundRaise;
